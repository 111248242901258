<template>
    <div class="home">
        <el-input @focus="dialogTableVisible = true" v-model="keyword" placeholder="Search everything..." clearable
            size="medium" suffix-icon="el-icon-search" style="width: 300px" />
        <el-dialog title="Searching" width="30%" :visible.sync="dialogTableVisible" append-to-body
            :before-close="handleClose">
            <div class="dialogDiv">
                <el-input slot="reference" v-model="searchContent" placeholder="Type something" clearable size="small"
                    suffix-icon="el-icon-search" style="margin-bottom: 20px; width: 400px" />
                <div class="search-content" ref="search">
                    <!-- 细节1：v-show与keyword绑定，实现删除搜索框全部内容时消失 -->
                    <div class="search-item" v-for="item of userList" :key="item.date">
                        <div style="font-size: 12px">{{ item.date }}</div>
                        <span>{{ item.name }} —— </span>
                        <span>{{ item.address }}</span>
                    </div>

                    <div class="search-item" v-show="hasData">No results...</div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-radio-group size="mini" v-model="radioValue">
                    <el-radio-button v-for="(item, index) in columns" :key="index" :label="item.value">{{ item.label }}
                    </el-radio-button>
                </el-radio-group>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            default: () => "",
        },
        labelArray: {
            type: Array,
            default: () => [],
        },
        columnArray: {
            type: Array,
            default: () => [],
        },
    },
    name: "Home",
    components: {},
    data() {
        return {
            selectionList: [],
            ids: [],
            radioValue: "Customer",
            searchContent: "",
            // 总条数
            total: 0,
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                userName: undefined,
                phonenumber: undefined,
                status: undefined,
                deptId: undefined,
            },
            dialogTableVisible: false,
            keyword: "",
            // 遮罩层
            loading: true,
            userList: [],
            // columns: [],
            // 列信息
            columns: [
                { key: 0, label: `Customer`, visible: true, value: "Customer" },
                { key: 1, label: `People`, visible: true, value: "People" },
                { key: 2, label: `Order`, visible: true, value: "Order" },
                { key: 3, label: `Files`, visible: true, value: "Files" },
                { key: 4, label: `Message`, visible: true, value: "Message" },
                { key: 4, label: `To-do`, visible: true, value: "Todo" },
            ],
            verificationImg: "",
        };
    },
    computed: {
        //判断关键词是否存在搜索源中，如不在，则“找不到相应结果”显示
        // 即通过v-show与hasData绑定来控制“找不到相应结果”的显现
        hasData() {
            return !this.userList.length;
        },
    },
    mounted() {
        this.getList();
        for (let i = 0; i < this.labelArray.length; i++) {
            this.columns.push({
                key: i,
                label: this.labelArray[i],
                visible: true,
                value: this.columnArray[i],
            });
        }
    },
    watch: {
        searchContent(val) {
            if (!val) {
                this.getList();
            }
            // String() 将一切都转为 String 类型
            return (this.userList = this.userList.filter((s) =>
                String(s[`${this.radioValue}`]).includes(val)
            ));
        },
    },
    methods: {
        handleClose() {
            this.dialogTableVisible = false;
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.selectionList = selection;
        },
        getList() {
            this.loading = true;
        },
        btnConfirm() {
            this.$emit("callback", this.selectionList);
            this.$emit("input", this.selectionList);
            this.dialogTableVisible = false;
        },
        btnConcel() {
            this.selectionList = [];
            this.dialogTableVisible = false;
        },
    },
};
</script>

<style lang="less" scoped>
@mainBlue: rgb(75, 102, 131);
@mainBlueHover: rgb(75, 102, 131, 0.8);

.dialogDiv {
    // height: 300px;
    overflow: overlay;
    position: relative;

    .close {
        position: absolute;
        top: -10px;
        right: 0;

        i {
            font-size: 22px;
            cursor: pointer;
        }
    }
}

.search-content {
    height: 300px;
    overflow: overlay;
    position: relative;

    // background-color: #eee;
    .search-item {
        line-height: 20px;
        padding: 10px 0 10px 10px;
        margin: 0 15px 0 0;
        color: #666;
        font-size: 16px;
        background-color: #fff;
        border-radius: 5px;

        &:hover {
            cursor: pointer;
            font-weight: bold;
            //border: 2px solid #e5e5e5;
            // border-bottom: 2px solid #e5e5e5;
            color: #fff;
            background: rgb(177, 176, 176);
            // transition: all 0.1s;
        }
    }
}

/deep/ .el-dialog__header {
    // border-bottom: 1px solid #e8eaec;
    padding: 5px 0 5px 5px;
    font-weight: 700;
    // color: #f8f8f8;
    // background: #535455;
}

/deep/ .el-dialog__footer {
    // border-top: 1px solid #e8eaec;
    padding: 10px 0 20px 15px;
    display: -webkit-flex;
    /* Safari */
    display: flex;
}

/deep/ .el-input__inner {
    background-color: transparent !important;
    background-image: none;
    border-radius: 0%;
    border: none;
    border-bottom: 2px solid #dcdfe6;

    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    // color: #fff;
    font-size: 16px;
    font-weight: 700;

    // -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    // transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    // width: 90%;
}

/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #fff;
    background-color: @mainBlue;
    border-color: @mainBlue;
    box-shadow: -1px 0 0 0 @mainBlue;
}
</style>
